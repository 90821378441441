
import { Options, Vue } from 'vue-class-component';
import AlreadyRegistered from '../components/alreadyregistered.vue';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        AlreadyRegistered
    }
})
export default class SharePosition extends Vue {

    created() {
    }

    getCurrentPosition(){
        LocalizationServices.getCurrentLocation()
        .then( x => {
            this.$router.push("/registerphone")
        }).catch( x => {
            
        })
    }

}
